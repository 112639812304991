<template>
  <div>
    <div>
      <div class="project-search">
        <div class="flex search-text align-center">
          <div>
            <span>编号：</span>
            <input v-model="pageInfo.code" placeholder="实验室编号" class="searchInput" />
          </div>
          <div class="marginLeft10">
            <span>名称：</span>
            <input v-model="pageInfo.name" placeholder="实验室名称" class="searchInput" />
          </div>
          <div class="marginLeft10">
            <span>学院名称：</span>
            <el-select v-model="pageInfo.collegeId" filterable placeholder="请选择一个学院" size="large">
              <el-option v-for="item in $root.collegeList" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </div>
          <button class="searchBtn" @click="search">搜索</button>
          <button class="resetBtn" @click="reset(null)">重置</button>
        </div>
      </div>
      <div class="project-table" style="margin-top: 20px">
        <div class="flex align-center justify-between paddingBTM20">
          <div class="fontSize20gay">实验室列表</div>
          <div class="flex">
            <div>
              <el-upload class="upload-demo" :action="(projectName?'/'+projectName:'')+'/laboratory/import?Authorization=Bearer '+token" list-type="picture" accept=".xls,.xlsx" :show-file-list="false" :on-progress="beforxfg" :on-success="fileVb">
                <button class="exportBtn">导入</button>
              </el-upload>
            </div>
            <button class="exportBtn marginLR10" @click="showDown('laboratory')">下载模板</button>
            <button class="addBtn" @click="showEdit('')"> + 新增</button>
          </div>
        </div>
        <el-table :data="tableData" border row-key="id"
                  :header-cell-style="{'text-align':'center','color':'#999999','padding':'16px 0','background-color':'#EBEBEB'}"
                  :cell-style="{'text-align':'center'}">
          <el-table-column prop="code" label="编号" />
          <el-table-column prop="name" label="实验室名称" />
          <el-table-column label="所属楼栋">
            <template #default="scope"><!-- scope.$index 表示 序列号 0、1、2 -->
              {{scope.row.building?scope.row.building.name:''}}
            </template>
          </el-table-column>
          <el-table-column prop="collegeNme" label="学院" />
          <el-table-column prop="address" label="地点" />
          <el-table-column prop="userNames" label="管理员" show-overflow-tooltip />
          <el-table-column prop="time" label="创建时间" />
          <el-table-column prop="time" label="是否开放预约">
            <template #default="scope"><!-- scope.$index 表示 序列号 0、1、2 -->
              <span class="fontSize12" :class="scope.row.open?'success':'error'">●</span><span class="marginLeft5">{{scope.row.open?'开放':'未开放'}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="time" label="是否对学生开放">
            <template #default="scope"><!-- scope.$index 表示 序列号 0、1、2 -->
              <span class="fontSize12" :class="scope.row.openStudent?'success':'error'">●</span><span class="marginLeft5">{{scope.row.openStudent?'开放':'未开放'}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="useSize" label="已用课时" />
          <el-table-column label="使用率">
            <template #default="scope"><!-- scope.$index 表示 序列号 0、1、2 -->
              {{scope.row.usageTate}}%
            </template>
          </el-table-column>
<!--          <el-table-column label="状态">
            <template #default="scope">
              <el-switch :model-value="!scope.row.close"
                         @change="changeClose(scope.row, 'open')"
                         class="ml-2" style="&#45;&#45;el-switch-on-color: #13ce66; &#45;&#45;el-switch-off-color: #ff4949"/>
            </template>
          </el-table-column>-->
          <el-table-column fixed="right" label="预约情况" width="100">
            <template #default="scope"><!-- scope.$index 表示 序列号 0、1、2 -->
              <el-button link type="primary" style="color: #3769B9" @click="seeLab(scope.row.id,scope.row.name)">查看</el-button>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="70">
            <template #default="scope"><!-- scope.$index 表示 序列号 0、1、2 -->
              <el-button link type="primary" style="color: #3769B9" @click="showEdit(scope.row)">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="flex justify-center paddingTD20">
          <!--   分页     -->
          <el-pagination
              :currentPage="pageInfo.page"
              :page-size="pageInfo.limit"
              :page-sizes="[10, 20, 50, 100]"
              :small="true"
              :disabled="false"
              :background="true"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageInfo.total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
    <!-- 操作窗口   -->
    <editDiv v-model="dialogFormVisible" :Row="seeInfo" @closeDiv="closeDiv" ref="editDiv"></editDiv>
  </div>
</template>

<script>
import {getRoomPage, downFile, laboratoryClose} from "@/api/api";
import editDiv from "@/view/room/roomYuYueInfo.vue"
import {projectName} from "@/utils/config"
export default {
  name: "roomList",
  components:{
    editDiv,
  },
  data(){
    return{
      token:localStorage.getItem('token'),
      tableData:[],
      dataInfo:[],
      projectName:projectName,
      Info:null,
      seeInfo:null,
      dialogFormVisible:false,
      pageInfo:{
        limit:10,
        name:'',
        code:'',
        page:1,
        total:0,
        collegeId:''
      },
      exportIds:''
    }
  },
  mounted() {
    this.getList();
  },
  methods:{
    changeClose(item, label = 'close'){
      const targetClose = label === 'close' ? '停用' : '启用'
      this.$confirm(`确认更改实验状态为${targetClose}吗?`, '提示', {
        confirmButtonText: '确认更改',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        laboratoryClose(item.id).then(res => {
          if (res.success) {
            item.close = !item.close
            this.$message.success('提交成功')
          }
        })
      })
    },
    reset(){
      this.pageInfo.name='';
      this.pageInfo.code='';
      this.pageInfo.collegeId='';
    },
    search(){
      this.pageInfo.limit=10;
      this.pageInfo.page=1;
      this.getList();
    },
    getList(){
      getRoomPage(this.pageInfo).then(res=>{
        this.pageInfo.total=parseInt(res.data.total);
        this.tableData=res.data.records;
      })
    },
    showEdit(row){
      this.Info=row;
      let data='';
      if(row) {
        data = JSON.stringify(this.Info);
      }
      this.$root.useRouter.push({path:"/room/roomEdit",query:{info:data}});
    },
    seeLab(id,name){
      this.seeInfo={id:id,name:name};
      this.dialogFormVisible=true;
      this.$refs.editDiv.getInfo(this.seeInfo);//调用子组件方法
    },
    showRenew(id){
      this.dialogFormVisible1=true;
      this.$refs.renewDiv.getInfo(id);//调用子组件方法
    },
    closeDiv(){
      this.dialogFormVisible=false;
    },
    handleSizeChange(e){//修改每页显示条数
      this.pageInfo.limit=e;
      this.pageInfo.page=1;
      this.getList();
    },
    handleCurrentChange(e){
      this.pageInfo.page=e;
      this.getList();
    },
    showDown(name){
      downFile(name);
    },
    beforxfg(){
      this.loading = this.$root.loadingOpen();
    },
    fileVb(res){
      if(res.success){
        this.$root.ElMessage({type:'success',message:res.message});
        this.getList();
      }else{
        this.$root.ElMessage.error(res.message);
      }
      this.loading.close();
    }
  }
}
</script>

<style lang="scss" scoped>
.data-title{font-size: 15px;color: #000000}
.data-content{
  font-size: 13px;display: flex;justify-content: space-between;margin-top: 15px;
}
</style>